h2 {
    margin-top: 24px;
}

h3 {
    margin-top: 20px;
}

h4 {
    margin-top: 16px;
}

ul, p {
    font-size: 14px;
    margin: 10px 0;
}

li {
  font-size: 14px;
  margin: 4px 0 4px 3%;
  list-style: square inside;
  list-style-position: outside;
}